/* Theme Carrot */


.theme-carrot {

  .dt-btn {
    &:hover {
      border-color: #e67e22; }
    &:after {
      background: #e67e22; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #e67e22;

    &:after {
      background: #e67e22; } }

  .section-title {
    &:after {
      background: #e67e22 !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #e67e22; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #e67e22 !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #e67e22;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(230, 126, 34, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #e67e22;

        i {
          background: #e67e22; } }

      &:hover {
        .item-icon {
          background: #e67e22; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #e67e22;

      &:before, &:after {
        background: #e67e22; } } }

  // About Us
  progress {
    background-color: #e67e22; }

  progress::-webkit-progress-value {
    background-color: #e67e22; }


  progress::-moz-progress-bar {
    background-color: #e67e22; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #e67e22; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #e67e22; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #e67e22; } } } } }


  // Map
  .map {
    background: #e67e22; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #e67e22; } } } }


  .news-item {
    .news-content {
      span {
        color: #e67e22; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #e67e22; } } } }


  .copyright-bar {
    p {
      a {
        color: #e67e22; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #e67e22; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #e67e22; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #e67e22; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #e67e22; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #e67e22; } }

    .aboute-details {
      p {
        &:before {
          background: #e67e22; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #e67e22; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(230,126, 34, 0.2);

          &:after {
            background-color: rgba(230,126, 34, 0.4); }

          i {
            background: #e67e22; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #e67e22; } } }

  .design-details {
    li {
      i {
        color: #e67e22; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #e67e22; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #e67e22; } }

        .support-title {
          h5 {
            color: #e67e22; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #e67e22; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #e67e22; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #e67e22; }

        i {
          color: #e67e22; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #e67e22; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #e67e22; } } }

        .post-date {
          li {
            color: #e67e22; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #e67e22; }

          p {
            color: #e67e22; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #e67e22; } }

      h3 {
        a {
          &:hover {
            color: #e67e22; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #e67e22; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #e67e22; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #e67e22; } } } }

    h3 {
      &:after {
        background: #e67e22; } } }

  .user-comments {
    h3 {
      &:after {
        background: #e67e22; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #e67e22; }

        span {
          &:after {
            background: #e67e22; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #e67e22; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #e67e22; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #e67e22; } } }

    .form-input {
      input {
        outline-color: #e67e22; } }

    .textarea {
      #dt-massage {
        outline-color: #e67e22; } }

    .contact-btn {
      background: #e67e22; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #e67e22; } } } } }


/* Theme Alizarin */

.theme-alizarin {

  .dt-btn {
    &:hover {
      border-color: #e74c3c; }
    &:after {
      background: #e74c3c; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #e74c3c;

    &:after {
      background: #e74c3c; } }

  .section-title {
    &:after {
      background: #e74c3c !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #e74c3c; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #e74c3c !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #e74c3c;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(231, 76, 60, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #e74c3c;

        i {
          background: #e74c3c; } }

      &:hover {
        .item-icon {
          background: #e74c3c; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #e74c3c;

      &:before, &:after {
        background: #e74c3c; } } }

  // About Us
  progress {
    background-color: #e74c3c; }

  progress::-webkit-progress-value {
    background-color: #e74c3c; }


  progress::-moz-progress-bar {
    background-color: #e74c3c; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #e74c3c; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #e74c3c; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #e74c3c; } } } } }


  // Map
  .map {
    background: #e74c3c; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #e74c3c; } } } }


  .news-item {
    .news-content {
      span {
        color: #e74c3c; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #e74c3c; } } } }


  .copyright-bar {
    p {
      a {
        color: #e74c3c; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #e74c3c; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #e74c3c; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #e74c3c; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #e74c3c; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #e74c3c; } }

    .aboute-details {
      p {
        &:before {
          background: #e74c3c; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #e74c3c; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #e74c3c; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #e74c3c; } } }

  .design-details {
    li {
      i {
        color: #e74c3c; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #e74c3c; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #e74c3c; } }

        .support-title {
          h5 {
            color: #e74c3c; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #e74c3c; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #e74c3c; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #e74c3c; }

        i {
          color: #e74c3c; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #e74c3c; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #e74c3c; } } }

        .post-date {
          li {
            color: #e74c3c; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #e74c3c; }

          p {
            color: #e74c3c; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #e74c3c; } }

      h3 {
        a {
          &:hover {
            color: #e74c3c; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #e74c3c; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #e74c3c; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #e74c3c; } } } }

    h3 {
      &:after {
        background: #e74c3c; } } }

  .user-comments {
    h3 {
      &:after {
        background: #e74c3c; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #e74c3c; }

        span {
          &:after {
            background: #e74c3c; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #e74c3c; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #e74c3c; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #e74c3c; } } }

    .form-input {
      input {
        outline-color: #e74c3c; } }

    .textarea {
      #dt-massage {
        outline-color: #e74c3c; } }

    .contact-btn {
      background: #e74c3c; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #e74c3c; } } } } }



/* Theme Amethyst */

.theme-amethyst {

  .dt-btn {
    &:hover {
      border-color: #9b59b6; }
    &:after {
      background: #9b59b6; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #9b59b6;

    &:after {
      background: #9b59b6; } }

  .section-title {
    &:after {
      background: #9b59b6 !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #9b59b6; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #9b59b6 !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #9b59b6;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(155, 89, 182, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #9b59b6;

        i {
          background: #9b59b6; } }

      &:hover {
        .item-icon {
          background: #9b59b6; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #9b59b6;

      &:before, &:after {
        background: #9b59b6; } } }

  // About Us
  progress {
    background-color: #9b59b6; }

  progress::-webkit-progress-value {
    background-color: #9b59b6; }


  progress::-moz-progress-bar {
    background-color: #9b59b6; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #9b59b6; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #9b59b6; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #9b59b6; } } } } }


  // Map
  .map {
    background: #9b59b6; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #9b59b6; } } } }


  .news-item {
    .news-content {
      span {
        color: #9b59b6; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #9b59b6; } } } }


  .copyright-bar {
    p {
      a {
        color: #9b59b6; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #9b59b6; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #9b59b6; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #9b59b6; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #9b59b6; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #9b59b6; } }

    .aboute-details {
      p {
        &:before {
          background: #9b59b6; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #9b59b6; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #9b59b6; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #9b59b6; } } }

  .design-details {
    li {
      i {
        color: #9b59b6; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #9b59b6; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #9b59b6; } }

        .support-title {
          h5 {
            color: #9b59b6; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #9b59b6; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #9b59b6; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #9b59b6; }

        i {
          color: #9b59b6; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #9b59b6; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #9b59b6; } } }

        .post-date {
          li {
            color: #9b59b6; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #9b59b6; }

          p {
            color: #9b59b6; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #9b59b6; } }

      h3 {
        a {
          &:hover {
            color: #9b59b6; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #9b59b6; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #9b59b6; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #9b59b6; } } } }

    h3 {
      &:after {
        background: #9b59b6; } } }

  .user-comments {
    h3 {
      &:after {
        background: #9b59b6; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #9b59b6; }

        span {
          &:after {
            background: #9b59b6; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #9b59b6; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #9b59b6; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #9b59b6; } } }

    .form-input {
      input {
        outline-color: #9b59b6; } }

    .textarea {
      #dt-massage {
        outline-color: #9b59b6; } }

    .contact-btn {
      background: #9b59b6; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #9b59b6; } } } } }


/* Theme River */
.theme-river {
  .dt-btn {
    &:hover {
      border-color: #ffbb00; }
    &:after {
      background: #ffbb00; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #ffbb00;

    &:after {
      background: #ffbb00; } }

  .section-title {
    &:after {
      background: #ffbb00 !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #ffbb00; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #ffbb00 !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #ffbb00;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(255, 187, 0, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #ffbb00;

        i {
          background: #ffbb00; } }

      &:hover {
        .item-icon {
          background: #ffbb00; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #ffbb00;

      &:before, &:after {
        background: #ffbb00; } } }

  // About Us
  progress {
    background-color: #ffbb00; }

  progress::-webkit-progress-value {
    background-color: #ffbb00; }


  progress::-moz-progress-bar {
    background-color: #ffbb00; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #ffbb00; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #ffbb00; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #ffbb00; } } } } }


  // Map
  .map {
    background: #ffbb00; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #ffbb00; } } } }


  .news-item {
    .news-content {
      span {
        color: #ffbb00; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #ffbb00; } } } }


  .copyright-bar {
    p {
      a {
        color: #ffbb00; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #ffbb00; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #ffbb00; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #ffbb00; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #ffbb00; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #ffbb00; } }

    .aboute-details {
      p {
        &:before {
          background: #ffbb00; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #ffbb00; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #ffbb00; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #ffbb00; } } }

  .design-details {
    li {
      i {
        color: #ffbb00; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #ffbb00; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #ffbb00; } }

        .support-title {
          h5 {
            color: #ffbb00; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #ffbb00; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #ffbb00; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #ffbb00; }

        i {
          color: #ffbb00; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #ffbb00; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #ffbb00; } } }

        .post-date {
          li {
            color: #ffbb00; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #ffbb00; }

          p {
            color: #ffbb00; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #ffbb00; } }

      h3 {
        a {
          &:hover {
            color: #ffbb00; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #ffbb00; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #ffbb00; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #ffbb00; } } } }

    h3 {
      &:after {
        background: #ffbb00; } } }

  .user-comments {
    h3 {
      &:after {
        background: #ffbb00; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #ffbb00; }

        span {
          &:after {
            background: #ffbb00; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #ffbb00; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #ffbb00; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #ffbb00; } } }

    .form-input {
      input {
        outline-color: #ffbb00; } }

    .textarea {
      #dt-massage {
        outline-color: #ffbb00; } }

    .contact-btn {
      background: #ffbb00; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #ffbb00; } } } } }


/* Theme Turquoise */
.theme-turquoise {
  .dt-btn {
    &:hover {
      border-color: #1abc9c; }
    &:after {
      background: #1abc9c; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #1abc9c;

    &:after {
      background: #1abc9c; } }

  .section-title {
    &:after {
      background: #1abc9c !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #1abc9c; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #1abc9c !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #1abc9c;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(26, 188, 156, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #1abc9c;

        i {
          background: #1abc9c; } }

      &:hover {
        .item-icon {
          background: #1abc9c; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #1abc9c;

      &:before, &:after {
        background: #1abc9c; } } }

  // About Us
  progress {
    background-color: #1abc9c; }

  progress::-webkit-progress-value {
    background-color: #1abc9c; }


  progress::-moz-progress-bar {
    background-color: #1abc9c; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #1abc9c; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #1abc9c; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #1abc9c; } } } } }


  // Map
  .map {
    background: #1abc9c; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #1abc9c; } } } }


  .news-item {
    .news-content {
      span {
        color: #1abc9c; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #1abc9c; } } } }


  .copyright-bar {
    p {
      a {
        color: #1abc9c; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #1abc9c; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #1abc9c; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #1abc9c; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #1abc9c; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #1abc9c; } }

    .aboute-details {
      p {
        &:before {
          background: #1abc9c; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #1abc9c; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #1abc9c; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #1abc9c; } } }

  .design-details {
    li {
      i {
        color: #1abc9c; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #1abc9c; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #1abc9c; } }

        .support-title {
          h5 {
            color: #1abc9c; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #1abc9c; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #1abc9c; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #1abc9c; }

        i {
          color: #1abc9c; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #1abc9c; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #1abc9c; } } }

        .post-date {
          li {
            color: #1abc9c; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #1abc9c; }

          p {
            color: #1abc9c; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #1abc9c; } }

      h3 {
        a {
          &:hover {
            color: #1abc9c; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #1abc9c; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #1abc9c; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #1abc9c; } } } }

    h3 {
      &:after {
        background: #1abc9c; } } }

  .user-comments {
    h3 {
      &:after {
        background: #1abc9c; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #1abc9c; }

        span {
          &:after {
            background: #1abc9c; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #1abc9c; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #1abc9c; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #1abc9c; } } }

    .form-input {
      input {
        outline-color: #1abc9c; } }

    .textarea {
      #dt-massage {
        outline-color: #1abc9c; } }

    .contact-btn {
      background: #1abc9c; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #1abc9c; } } } } }


/* Theme Emerald */
.theme-emerald {
  .dt-btn {
    &:hover {
      border-color: #2ecc71; }
    &:after {
      background: #2ecc71; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #2ecc71;

    &:after {
      background: #2ecc71; } }

  .section-title {
    &:after {
      background: #2ecc71 !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #2ecc71; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #2ecc71 !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #2ecc71;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(46, 204, 113, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #2ecc71;

        i {
          background: #2ecc71; } }

      &:hover {
        .item-icon {
          background: #2ecc71; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #2ecc71;

      &:before, &:after {
        background: #2ecc71; } } }

  // About Us
  progress {
    background-color: #2ecc71; }

  progress::-webkit-progress-value {
    background-color: #2ecc71; }


  progress::-moz-progress-bar {
    background-color: #2ecc71; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #2ecc71; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #2ecc71; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #2ecc71; } } } } }


  // Map
  .map {
    background: #2ecc71; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #2ecc71; } } } }


  .news-item {
    .news-content {
      span {
        color: #2ecc71; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #2ecc71; } } } }


  .copyright-bar {
    p {
      a {
        color: #2ecc71; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #2ecc71; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #2ecc71; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #2ecc71; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #2ecc71; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #2ecc71; } }

    .aboute-details {
      p {
        &:before {
          background: #2ecc71; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #2ecc71; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #2ecc71; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #2ecc71; } } }

  .design-details {
    li {
      i {
        color: #2ecc71; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #2ecc71; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #2ecc71; } }

        .support-title {
          h5 {
            color: #2ecc71; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #2ecc71; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #2ecc71; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #2ecc71; }

        i {
          color: #2ecc71; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #2ecc71; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #2ecc71; } } }

        .post-date {
          li {
            color: #2ecc71; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #2ecc71; }

          p {
            color: #2ecc71; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #2ecc71; } }

      h3 {
        a {
          &:hover {
            color: #2ecc71; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #2ecc71; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #2ecc71; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #2ecc71; } } } }

    h3 {
      &:after {
        background: #2ecc71; } } }

  .user-comments {
    h3 {
      &:after {
        background: #2ecc71; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #2ecc71; }

        span {
          &:after {
            background: #2ecc71; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #2ecc71; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #2ecc71; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #2ecc71; } } }

    .form-input {
      input {
        outline-color: #2ecc71; } }

    .textarea {
      #dt-massage {
        outline-color: #2ecc71; } }

    .contact-btn {
      background: #2ecc71; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #2ecc71; } } } } }



/* Theme Asphalt */
.theme-asphalt {
  .dt-btn {
    &:hover {
      border-color: #34495e; }
    &:after {
      background: #34495e; } }

  .dt-btn-white {
    &:hover {
      border-color: #FFF; } }

  .btn-skew {
    border: 2px solid #34495e;

    &:after {
      background: #34495e; } }

  .section-title {
    &:after {
      background: #34495e !important; } }

  .dt-social-link {
    li {
      a {
        &:hover {
          color: #34495e; } } } }

  .navbar {
    .navbar-nav {
      li {
        a {
          &:hover {
            color: #34495e !important; } }

        .child-menu {
          li {
            &:hover {
              background-color:  #34495e;

              a {
                color: #FFF !important;
                display: block; } } } } } } }


  // Feature
  .feature {
    .overlay-content {
      background-color: rgba(52, 73, 94, 0.8); } }

  // Our Feature
  .feature-item {
    .featured {
      .item-icon {
        border-color: #34495e;

        i {
          background: #34495e; } }

      &:hover {
        .item-icon {
          background: #34495e; } } } }


  // Finance
  .counter {
    .count-item {
      border-color: #34495e;

      &:before, &:after {
        background: #34495e; } } }

  // About Us
  progress {
    background-color: #34495e; }

  progress::-webkit-progress-value {
    background-color: #34495e; }


  progress::-moz-progress-bar {
    background-color: #34495e; }



  // Client Feedback
  .owl-dots {

    .owl-dot {
      &:hover,

      &.active {
        span {
          background: #34495e; } } } }

  // Team
  .team {
    .team-member {
      .overlay {
        .name {
          h3 {
            color: #34495e; } } } } }

  // Latest News
  #letest-news {
    .news-caption {
      .news-title {
        a {
          &:hover {
            color: #34495e; } } } } }


  // Map
  .map {
    background: #34495e; }

  // Footer
  .dt-service-menu {
    li {
      a {
        &:hover {
          color: #34495e; } } } }


  .news-item {
    .news-content {
      span {
        color: #34495e; } } }

  .footer-menu {
    li {
      a {
       &:hover {
        color: #34495e; } } } }


  .copyright-bar {
    p {
      a {
        color: #34495e; } } }


  // Home Two

  // Feature Two

  #feature-two {
    .feature {
      .feature-box {
        &:hover {
          i {
            color: #34495e; } } } } }

  #theme {
    .theme-discription {
      p {
        &:before {
          background: #34495e; } } } }

  // Client
  .client-details {
    .details {
      h5 {
        color: #34495e; }

      .dt-social-link {
        li {
          a {
            &:hover {
              color: #34495e; } } } } } }

  // About
  .aboute-caption {
    .aboute-title {
      span {
        color: #34495e; } }

    .aboute-details {
      p {
        &:before {
          background: #34495e; } } } }

  // Team Two
  #team-two {
    .swiper-slide {
      .slider-content {
        h4 {
          color: #34495e; } } } }


  // Design
  .design-item {
    .design {
      .icon-box {

        &:hover {
          background-color: rgba(231, 76, 60, 0.2);

          &:after {
            background-color: rgba(231, 76, 60, 0.4); }

          i {
            background: #34495e; } } } } }

  .design-content {
    .desing-caption {
      span {
        color: #34495e; } } }

  .design-details {
    li {
      i {
        color: #34495e; } } }


  // Home Three
  #feature-top-three {
    .feature-items {
      .box {
        .feature-thumb {
          .overlay {
            background-color: rgba(230, 126, 34, 0.7); } } } } }



  // About
  .page-banner {
    .banner-title {
      p {
        span {
          color: #34495e; } } } }



  // Insurance
  .insu-support {
    .support {
      &:hover {
        .support-logo {
          i {
            color: #34495e; } }

        .support-title {
          h5 {
            color: #34495e; } } } } }


  // Widget
  .widget {
    .widget-title {
      &:after {
        background: #34495e; } } }

  .widget_nav_menu {
    ul {
      li {
        a {
          &:hover {
            color: #34495e; } } } } }

  .download {
    li {
      &:hover {
        a {
          color: #34495e; }

        i {
          color: #34495e; } } } }

  .dt-tag {
    a {
      &:hover {
        background: #34495e; } } }


  .widget_recent_entries {
    .resent-post {
      .post-content {
        h5 {
          a {
            &:hover {
              color: #34495e; } } }

        .post-date {
          li {
            color: #34495e; } } } } }


  .widget_archive {
    ul {
      li {
        &:hover {
          a {
            color: #34495e; }

          p {
            color: #34495e; } } } } }



  // Blog
  .blog-all-item {
    .news {
      .post-date {
        a {
          color: #34495e; } }

      h3 {
        a {
          &:hover {
            color: #34495e; } } } } }

  .page-control {
    .control {
      li {
        &:hover {
          background: #34495e; } } } }

  // Blog Two
  .blog-item-two {
    .news {
      .news-post {
        h3 {
          a {
            &:hover {
              color: #34495e; } } } } } }


  // Blog-Three
  .comments {
    .media-list {
      .media-body {
        .comment-date {
          li {
            color: #34495e; } } } }

    h3 {
      &:after {
        background: #34495e; } } }

  .user-comments {
    h3 {
      &:after {
        background: #34495e; } } }



  // Team
  .expart-team {
    .team-member {
      .overlay {
        h3 {
          color: #34495e; }

        span {
          &:after {
            background: #34495e; } } } } }

  .team-details {
    .team-caption {
      .content {
        span {
          color: #34495e; }

        .dt-social-link {
          li {
            a {
              &:hover {
                color: #34495e; } } } } } } }


  // Contact Page
  .contact {
    .contact-title {
      h3 {
        &:after {
          background: #34495e; } } }

    .form-input {
      input {
        outline-color: #34495e; } }

    .textarea {
      #dt-massage {
        outline-color: #34495e; } }

    .contact-btn {
      background: #34495e; } }

  .contact-address {
    .contant-info {
      .icon {
        i {
          color: #34495e; } } } } }


